//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    onClose() {
      console.log('onClose')
    }
  }
}
