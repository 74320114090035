//
//
//
//
//
//
//
//
//

import formPreview from './components/formPreview.vue'
import formPrivewContent from './components/formPrivewContent.vue'
import { jsonData } from './config'
export default {
  components: {
    formPreview,
    formPrivewContent
  },
  data() {
    return {
      jsonData
    }
  },
  methods: {
    handleSave(evt) {
      console.log(evt)
      this.jsonData = JSON.parse(evt)
      this.$refs.formPreview.open()
    }
  }
}
